import { Modal, Text, Button, Group, Box, Stack, Flex, Avatar, SimpleGrid } from '@mantine/core';
import { getRoute } from "~/routes";
import { useFetcher, useNavigate } from "@remix-run/react";
import { useEffect, useState } from "react";
import { notifications } from "@mantine/notifications";
import { IconCheck, IconPalette, IconDownload, IconBrandX, IconArrowRight, IconBrandInstagram, IconBrandTiktok, IconBrandYoutube, IconBrandLinkedin } from '@tabler/icons-react';
import { TemplateItem } from './TemplateItem';
import { getTemplates } from './getTemplates';

interface TemplatePreviewModalProps {
  opened: boolean;
  onClose: () => void;
  template: {
    templateId: number;
    title: string;
    description: string;
    thumbnailUrl: string;
    craftedBy?: string;
    logoUrl?: string;
    useCase?: string;
  };
  isAuthenticated: boolean;
}

export function TemplatePreviewModal({ opened, onClose, template: initialTemplate, isAuthenticated }: TemplatePreviewModalProps) {
  const [currentTemplate, setCurrentTemplate] = useState(initialTemplate);
  const fetcher = useFetcher();
  const navigate = useNavigate();
  const isLoading = fetcher.state === "submitting";
  const templates = getTemplates();

  // Reset current template when initial template changes
  useEffect(() => {
    setCurrentTemplate(initialTemplate);
  }, [initialTemplate]);

  const handleSimilarTemplateClick = (newTemplate: typeof initialTemplate) => {
    setCurrentTemplate(newTemplate);
  };

  const handleAction = () => {
    if (!isAuthenticated) {
      navigate(getRoute('register'));
      return;
    }

    fetcher.submit(
      { templateId: currentTemplate.templateId.toString() },
      { 
        method: 'post', 
        action: getRoute('createFromTemplate', { 
          template_id: currentTemplate.templateId 
        })
      }
    );
  };

  // Add back the effect to handle the fetcher response
  useEffect(() => {
    if (fetcher.data?.error) {
      notifications.show({
        title: 'Error',
        message: fetcher.data.error,
        color: 'red',
        autoClose: false,
      });
    } else if (fetcher.data?.success) {
      if (fetcher.data.video) {
        navigate(getRoute('video', { video_slug: fetcher.data.video.slug }));
      }
    }
  }, [fetcher.data, navigate]);

  return (
    <Modal
      opened={opened}
      onClose={onClose}
      size="90vw"
      centered
      styles={{
        inner: {
          padding: '20px'
        },
        content: {
          maxWidth: '1400px',
          width: '100%'
        }
      }}
    >
      <Flex gap="xl">
        {/* Left Column - Video */}
        <Box style={{ flex: '0 0 52%' }}>
          <video
              style={{
                width: "100%",
                borderRadius: 10,
              } as React.CSSProperties}
              src={currentTemplate.thumbnailUrl}
              autoPlay
              loop
              muted
              playsInline
              controls
              className="custom-video-player"
          />
        </Box>

        {/* Right Column - Content */}
        <Stack spacing="md" style={{ flex: '1' }}>
          <Stack spacing="xs">
            <Text size="xl" fw={600}>{currentTemplate.title}</Text>
            
            {currentTemplate.craftedBy && (
              <Group>
                <Avatar src={currentTemplate.logoUrl} size="sm" />
                <Text size="sm" c="dimmed">{currentTemplate.craftedBy}</Text>
              </Group>
            )}
          </Stack>

          {/* Video Details */}
          <Text size="sm" c="dimmed">Video (1080p) • 1920×1080px • mp4</Text>

          {/* Features List */}
          <Stack spacing="xs">
            <Group align="center" gap="xs">
              <IconPalette size={16} style={{ color: 'var(--mantine-color-gray-6)' }} />
              <Text size="sm">100% fully customizable</Text>
            </Group>
            <Group align="center" gap="xs">
              <IconDownload size={16} style={{ color: 'var(--mantine-color-gray-6)' }} />
              <Text size="sm">Edit and download</Text>
            </Group>
            <Group align="center" gap="xs">
              <IconBrandInstagram size={16} style={{ color: 'var(--mantine-color-gray-6)' }} />
              <IconBrandX size={16} style={{ color: 'var(--mantine-color-gray-6)' }} />
              <IconBrandTiktok size={16} style={{ color: 'var(--mantine-color-gray-6)' }} />
              <IconBrandYoutube size={16} style={{ color: 'var(--mantine-color-gray-6)' }} />
              <IconBrandLinkedin size={16} style={{ color: 'var(--mantine-color-gray-6)' }} />
              {/* <Text size="sm">Publish anywhere</Text> */}
            </Group>
          </Stack>

          {/* Description */}
          <Text size="sm">{currentTemplate.description}</Text>
          <Text size="sm">{currentTemplate.useCase}</Text>

          {/* Action Buttons */}
          <Group grow>
            <Button
              size="md"
              loading={isLoading || fetcher.state === "loading"}
              onClick={handleAction}
              rightSection={<IconArrowRight size={16} />}
            >
              {isAuthenticated ? 'Customize this template' : 'Create a free account'}
            </Button>
          </Group>
        </Stack>
      </Flex>

      {/* More Like This Section */}
      <Box mt={40}>
        <Text size="lg" fw={600} mb="md">More like this</Text>
        <SimpleGrid cols={4}>
          {templates
            .filter(t => t.templateId !== currentTemplate.templateId)
            .slice(0, 4)
            .map((relatedTemplate) => (
              <Box 
                key={relatedTemplate.templateId} 
                onClick={() => handleSimilarTemplateClick(relatedTemplate)}
                style={{ cursor: 'pointer' }}
                p="xs"
              >
                <TemplateItem
                  template={relatedTemplate}
                  isAuthenticated={isAuthenticated}
                />
              </Box>
          ))}
        </SimpleGrid>
      </Box>
    </Modal>
  );
} 